'use strict';

if(window.addEventListener) {
	window.addEventListener('load', pageLoaded);
} else if(window.attachEvent) {
	window.attachEvent('load', pageLoaded);
} else {
	window.onload = pageLoaded;
}


function pageLoaded() {
	var win_w = window.innerWidth;
	if(win_w > 992) {
		diagramLoad(3, 'circleSide', 'controller');
		diagramLoad(3, 'processCircle', 'investment-controller');
	}
	
}

function diagramLoad(start, diagram, controllers) {
	//investment-philosophy-diagram
	var diagramBlocks = document.getElementsByClassName(diagram);
	var controllers = document.getElementsByClassName(controllers);


	if(diagramBlocks.length > 0) {
		/* INITIAL BY STARTING LAST ELEMENT */
		var animatedElement = start;
		var base = diagramBlocks[animatedElement];
		var content = diagramBlocks[animatedElement].getAttribute('data-content');
		var action = 'add';
		toggleActivated(base, content, action, controllers);


	 	/* CONTROLLERS */
		for(var c = 0; c < controllers.length; c++) {
			controllers[c].addEventListener('click', function() {
				
				var controllerAction = this.getAttribute('data-action');
				var activeElement = controllerAction;


				/* CLEANS ALL HIGHLIGHTED */
				for(var cl = 0; cl < diagramBlocks.length; cl++) {
					/* check if it has class active */
					var blockClassList = diagramBlocks[cl].classList;
					if ((blockClassList != null) && (blockClassList != undefined)) {
						if(diagramBlocks[cl].classList.contains('active') == true) {
							var activeElement = cl;
						}
					} else {
						if(((' '+diagramBlocks[cl].getAttribute('class')+' ').indexOf(' active ') > -1) == true) {
							var activeElement = cl;
						}
					}
					var clearBase = diagramBlocks[cl];
					var clearContent = diagramBlocks[cl].getAttribute('data-content');
					var clearAction = 'remove';
					toggleActivated(clearBase, clearContent, clearAction, controllers);
				}


				if(controllerAction < 0) {
					activeElement = activeElement - 1;
					if(activeElement < 0) {
						activeElement = diagramBlocks.length-1;
					}
				} else if(controllerAction > diagramBlocks.length-1) {
					activeElement = activeElement + 1;
					if(activeElement > diagramBlocks.length-1) {
						activeElement = 0;
					}
				} else {
					activeElement = controllerAction;
				}
	 			console.log(activeElement);
				var base = diagramBlocks[activeElement];
				var content = diagramBlocks[activeElement].getAttribute('data-content');
				var action = 'add';

				toggleActivated(base, content, action, controllers);

			});
		}


		/* ATTACHES HOVER LISTENERS */
		for(var i = 0; i < diagramBlocks.length; i++) {
			diagramBlocks[i].addEventListener('mouseover', function() {
				for(var i = 0; i < diagramBlocks.length; i++) {
					var clearBase = diagramBlocks[i];
					var clearContent = diagramBlocks[i].getAttribute('data-content');
					var action = 'remove'
					toggleActivated(clearBase, clearContent, action, controllers);
				}
				var base = this;
				var content = this.getAttribute('data-content');
				var action = 'add';
				toggleActivated(base, content, action, controllers);	
			});
			
		}
	}
	

}






//SVG DIAGRAM EFFECT ON HOVER TOGGLE EFFECT
function toggleActivated(base, content, func, controllers) {
	var baseEl = base;
	var selectEl = content;
	var action = func;
	var elementOrder = base.getAttribute('data-order');
	var diagramControls = controllers;

	var openText;
	var getWindowWidth = window.innerWidth;

	/* check what screen size is loaded */
	if(getWindowWidth >= 992) {
		var textBoxToAnimate = baseEl.getAttribute('data-key');
		openText = document.getElementById(textBoxToAnimate);
	}

	var selectedEl = document.getElementsByClassName(selectEl);

	if(((action != undefined) || (action != null)) && (selectedEl.length > 0)) {
		if(action == 'add') {

			for(var i = 0; i < selectedEl.length; i++) {
				/* MAKES TEXT TO ACTIVE */
				var selectedClassList = selectedEl.item(i).classList;
				if ((selectedClassList != null) && (selectedClassList != undefined)) {
					if(selectedEl.item(i) != undefined) {
						if(selectedEl.item(i).classList.contains('active') == false) {
							selectedEl.item(i).classList.add('active');
						}
					}
				} else {
					if(selectedEl.item(i) != undefined) {
						/* IE9 Fallback */
						if(((" " + selectedEl.item(i).getAttribute('class') + " ").indexOf(" active ") > -1) == false) {
							selectedEl.item(i).className.baseVal += ' active ';

						}
						
					}
				}
				/* MAKES CONTROLLERS TO HIGHLIGHT */
				for(var z = 0; z < diagramControls.length; z++) {
					if(diagramControls[z].getAttribute('data-action') == elementOrder) {
						var diagramClassList = diagramControls[z].classList;
						if((diagramClassList != null) && (diagramClassList != undefined)) {
							diagramControls[z-1].classList.add('active')
						} else {

							diagramControls[z-1].className +=' active';
						}
						//diagramControls[z-1].classList.add('active');
					} else {
						var diagramClassList = diagramControls[z].classList;
						var tempVar = 0;
						if((z - 1) < 0) {
							tempVar = 0;
						} else {
							tempVar = z - 1;
						}
						if((diagramClassList != null) && (diagramClassList != undefined)) {
							
							diagramControls[tempVar].classList.remove('active');
						} else {
							diagramControls[tempVar].className = diagramControls[tempVar].className.replace(new RegExp('(?:^|\\s)'+ 'active' + '(?:\\s|$)'), ' ');
						}	
					}
				}
				var baseElClasslist = baseEl.classList; 
				if((baseElClasslist != null) || (baseElClasslist != undefined)) {
					baseEl.classList.add('active')
				} else {
					baseEl.className.baseVal += ' active ';
				}

				// open text block
				if(openText != undefined) {
					openText.style.display = 'block';
				}
			}
		} else if(action == 'remove') {
			for(var i = 0; i < selectedEl.length; i++) {
				var selectedClassList = selectedEl.item(i).classList;
				if ((selectedClassList != null) && (selectedClassList != undefined)) {
					if(selectedEl.item(i) != undefined) {
						selectedEl.item(i).classList.remove('active');
					}
				} else {
					if(selectedEl.item(i) != undefined) {
						if(((" " + selectedEl.item(i).getAttribute('class') + " ").indexOf(" active ") > -1) == true) {
							/* IE9 Fallback */
							selectedEl.item(i).className.baseVal = selectedEl.item(i).className.baseVal.replace(new RegExp('(?:^|\\s)'+ 'active' + '(?:\\s|$)'), ' ');
						}
					}
				}
			}

			/* Get baseEl classList and add Active */
			if((baseEl.classList) != null && (baseEl.classList != undefined)) {
				baseEl.classList.remove('active');
			} else {
				baseEl.className.baseVal = baseEl.getAttribute('class').replace(new RegExp('(?:^|\\s)' + 'active' + '(?:\\s|$)'), ' ');
			}

			// close text block
			if(openText != undefined) {
				openText.style.display = 'none';
			}
		}
	}
}






