
if(document.getElementById('piechart') != undefined) {
  google.charts.load('current', {'packages':['corechart']});
  google.charts.setOnLoadCallback(drawChart);

  var graphTitles = document.getElementsByClassName('graph-titles');
  var graphValues = document.getElementsByClassName('graph-values');


    function drawChart() {
        var data = google.visualization.arrayToDataTable([
            ['Sectors', 'Percentages'],
            [graphTitles[0].textContent.trim(), (graphValues[0].textContent.trim() / 1)],
            [graphTitles[1].textContent.trim(), (graphValues[1].textContent.trim() / 1)],
            [graphTitles[2].textContent.trim(), (graphValues[2].textContent.trim() / 1)],
            [graphTitles[3].textContent.trim(), (graphValues[3].textContent.trim() / 1)],
            [graphTitles[4].textContent.trim(), (graphValues[4].textContent.trim() / 1)]
        ]);
        var options = {
        //title: 'My Daily Activities',
            sliceVisibilityThreshold: 0,
            pieHole: 0.7,
            colors: ['#4F748B', '#77777B', '#00ADD9', '#001F60', '#C5BCB7'],
            chartArea:{left:10,top:10,width:'90%',height:'90%'},
            tooltip: { trigger : 'selection', text : 'percentage' },
            legend : { position : 'none' },
        };
        var chart = new google.visualization.PieChart(document.getElementById('piechart'));

        google.visualization.events.addListener(chart, 'select', function() {
            var temp = chart.getSelection();
            var selectedItem = temp[0];
            
            if(selectedItem != undefined) {
                keyHighlighter(temp[0].row);
            } else {
                keyHighlighter();
            }
        });
        chart.draw(data, options);

        // MAKING THE KEYS CLICKABLE
        getKeysClickable = document.getElementsByClassName('single-key');
        for(var k=0; k<getKeysClickable.length; k++) {
            getKeysClickable[k].addEventListener('click', function() {
                var element_number = this.getAttribute('data-key');
                var element_state = this.getAttribute('data-state');

                if(element_state == 'none') {
                   chart.setSelection([{row: parseInt(element_number), column: null}]);
                   keyHighlighter(element_number);

                } else {
                   chart.setSelection([{row: null, column: null}]);
                   keyHighlighter(); 
                }
                
            });
        }

        // MAKING THE LEGENDS CLICKABLE
        getLegendsClickable = document.getElementsByClassName('single-legend');
        for(var l=0; l<getLegendsClickable.length; l++) {
            getLegendsClickable[l].addEventListener('click', function() {
                var legend_number = this.getAttribute('data-legend');
                var legend_state = this.getAttribute('data-state');
                
                if(legend_state == 'none') {
                   chart.setSelection([{row: parseInt(legend_number), column: null}]);
                   keyHighlighter(legend_number);
                } else {
                   chart.setSelection([{row: null, column: null}]);
                   keyHighlighter(); 
                }
            });
        }

        // SELECTS AND DE-SELECTS THE KEYS
        function keyHighlighter(el) {
            var getKeys = document.getElementsByClassName('single-key');
            var getLegends = document.getElementsByClassName('single-legend');

            if((el == undefined)) {
                //makes all keys visible
                for(var i = 0; i<getKeys.length; i++) {
                    getKeys[i].style.opacity = '1';
                    getKeys[i].setAttribute('data-state','none');
                }
                //makes all legends visible
                for(var l = 0; l<getLegends.length; l++) {
                    getLegends[l].style.opacity = '1';
                    getLegends[l].setAttribute('data-state','none');
                }
                return
            } 

            // checks and sets the keys 
            if(el <= (getKeys.length - 1)) {
                for(var i = 0; i<getKeys.length; i++) {
                    getKeys[i].style.opacity = '0.5';
                    getKeys[i].setAttribute('data-state','none');
                }
            } else {
                for(var i = 0; i<getKeys.length; i++) {
                    getKeys[i].style.opacity = '1';
                    getKeys[i].setAttribute('data-state','none');
                }
            }
            // checks and sets the legends
            if(el <= (getLegends.length - 1)) {
                for(var l = 0; l<getLegends.length; l++) {
                    getLegends[l].style.opacity= '0.5';
                    getLegends[l].setAttribute('data-state','none');
                }
            } else {
                for(var l = 0; l<getLegends.length; l++) {
                    getLegends[l].style.opacity= '1';
                    getLegends[l].setAttribute('data-state','none');
                }
            }

            // checks and sets the keys & legends to active 
            if(getKeys[el] != undefined) {
                getKeys[el].setAttribute('data-state','active');
                getKeys[el].style.opacity = '1';
            }
            if(getLegends[el] != undefined) {
                getLegends[el].setAttribute('data-state','active');
                getLegends[el].style.opacity = '1';
            }
           
        }



    } //end drawChart
}



