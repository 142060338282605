'use strict';

// MENU - OPEN 
// MENU - CLOSE
// HERO HEADER
// FAQ ACCORDION
// FOOTER MENU HEIGHT
// LOOP POSTS TITLE HEIGHT
// SENIOR TEAM TEXT HEIGHT
// MANAGEMENT TEAM TEXT HEIGHT
// SHOW DISCLAIMER
// CHECK FOR HASHES
// INFOGRAPHIC COLUMN SIZES
// SVG DIAGRAM EFFECT ON HOVER
// REMOVE GOOGLE MAP FILTER
// FRONT PAGE STATISTICS
// SAME HEIGHT FUNCTION
// CHECK HEADER SCROLLTOP
// HEADER SCROLLTOP
// ADD ANIMATION
// GOOGLE MAP INNIT



if (window.addEventListener) {
	window.addEventListener('load', websiteLoad);
} else if (window.attachEvent) {
	window.attachEvent('load', websiteLoad);
} else {
	window.onload = websiteLoad;
}
function websiteLoad() {
	// CHECK HEADER SCROLLTOP
	windowHeader();

	// ACTIVATE FRONT PAGE CAROUSEL
	var slider = document.querySelectorAll('.carousel-header .carousel .carousel-inner .item');
	if (slider.length > 0) {
		var sliderClass = slider[0].classList;
		if ((sliderClass != null) || (sliderClass != undefined)) {
			sliderClass.add += "active";
		} else {
			slider[0].className += "active";
		}
	}

	// CHECK QUICKLINKS HEIGHT
	if (jQuery('.carousel-indicators').length) {
		jQuery(window).on('resize', function () {
			var indicatorOffset = jQuery('.carousel-indicators').offset();
			var indicatorRight = jQuery('.carousel-indicators').outerWidth(true) + indicatorOffset.left;
			jQuery('.carousel-header.signposts .signposts-wrapper').css('right', (jQuery(window).width() - indicatorRight) * -1);
			var h = jQuery('.carousel-header.signposts .signposts-wrapper').outerHeight(true);
		}).resize();
	}

	//MENU - OPEN
	var menuBtnOpen = document.getElementById('menu-open-btn');
	if ((menuBtnOpen != null) || (menuBtnOpen != undefined)) {
		var sitenav = document.getElementById('site-menu');
		if (menuBtnOpen.addEventListener) {
			menuBtnOpen.addEventListener('click', menuBtnOpenFunc);
		} else if (menuBtnOpen.attachEvent) {
			menuBtnOpen.attachEvent('click', menuBtnOpenFunc);
		} else {
			menuBtnOpen.onclick = menuBtnOpenFunc;
		}
	}
	//MENU - CLOSE
	var menuBtnClose = document.getElementById('menu-close-btn');
	if ((menuBtnClose != null) || (menuBtnClose != undefined)) {
		var sitenav = document.getElementById('site-menu');
		if (menuBtnClose.addEventListener) {
			menuBtnClose.addEventListener('click', menuBtnOpenFunc);
		} else if (menuBtnClose.attachEvent) {
			menuBtnClose.attachEvent('click', menuBtnOpenFunc);
		} else {
			menuBtnClose.onclick = menuBtnOpenFunc;
		}
	}
	function menuBtnOpenFunc() {
		var menuBtnOpenClass = menuBtnOpen.classList;
		var sitenavClass = sitenav.classList;

		if ((menuBtnOpenClass != null) || (sitenavClass != null) && (menuBtnOpenClass != undefined) || (sitenavClass != undefined)) {
			if (menuBtnOpen.classList.contains('mobile-open')) {
				sitenav.classList.remove('site-menu-opened');
				menuBtnOpen.classList.remove('mobile-open');
			} else {
				sitenav.classList.add('site-menu-opened');
				menuBtnOpen.classList.add('mobile-open');
			}
		} else {
			/* IE 9 Support */
			var menuhasClass = (" " + menuBtnOpen.className + " ").indexOf(" mobile-open ") > -1;
			if (menuhasClass == true) {
				sitenav.className = sitenav.className.replace(new RegExp('(?:^|\\s)' + 'site-menu-opened' + '(?:\\s|$)'), ' ');
				menuBtnOpen.className = menuBtnOpen.className.replace(new RegExp('(?:^|\\s)' + 'mobile-open' + '(?:\\s|$)'), ' ');
			} else {
				sitenav.className += " site-menu-opened ";
				menuBtnOpen.className += " mobile-open ";
			}
		}
	}

	// FAQ ACCORDION
	var accordion = document.querySelectorAll('.faq-repeat li h4');
	var i;

	for (i = 0; i < accordion.length; i++) {
		accordion[i].onclick = function () {
			var accordionClass = this.classList;
			if ((accordionClass != null) || (accordionClass != undefined)) {
				this.classList.toggle('active');
			}

			var panel = this.nextElementSibling;
			if (panel.style.maxHeight) {
				panel.style.maxHeight = null;
				panel.style.minHeight = null;

				/* if panel is hidden, hide all child elements */
				var sub_accordion = panel.querySelectorAll('ul li h5');
				for (var sub = 0; sub < sub_accordion.length; sub++) {
					var subAccordionClass = sub_accordion[sub].classList;
					if ((subAccordionClass != null) || (subAccordionClass != undefined)) {
						sub_accordion[sub].classList.remove('active');
					}
					var subPanel = sub_accordion[sub].nextElementSibling;
					if (subPanel.style.maxHeight) {
						subPanel.style.maxHeight = null;
					}
				}
			} else {
				panel.style.maxHeight = "none";
				panel.style.minHeight = panel.scrollHeight + "px";

				/* if panel is open, add event listener to child */
				var sub_accordion = panel.querySelectorAll('ul li h5');
				for (var sub = 0; sub < sub_accordion.length; sub++) {
					sub_accordion[sub].onclick = function () {
						var subAccordionClass = this.classList;
						if ((subAccordionClass != null) || (subAccordionClass != undefined)) {
							this.classList.toggle('active');
						}
						var subPanel = this.nextElementSibling;
						if (subPanel.style.maxHeight) {
							subPanel.style.maxHeight = null;
						} else {
							subPanel.style.maxHeight = subPanel.scrollHeight + "px";
						}
					}
				}
			}
		}
	}

	// FOOTER MENU HEIGHT
	var menu_li = document.querySelectorAll('footer section.site-footer .container .footer-nav ul > li.menu-item-has-children');
	menu_li.item(3).style.minHeight = menu_li.item(0).offsetHeight + 'px';

	var animateNum = document.getElementsByClassName('animNum');
	for (var s = 0; s < animateNum.length; s++) {
		var end_num = parseInt(animateNum.item(s).innerHTML);
		animateValue(animateNum.item(s), 0, end_num, 1000);
	}

	// LOOP POSTS TITLE HEIGHT
	var loop_posts_titles = document.querySelectorAll('section.loop-posts ul li a h4');
	var loo_posts_text = document.querySelectorAll('section.loop-posts ul li a p');
	if (loop_posts_titles.length > 0) {
		sameHeight(loop_posts_titles);
	}
	if (loo_posts_text.length > 0) {
		sameHeight(loo_posts_text);
	}

	// SENIOR TEAM TEXT HEIGHT
	var senior_title = document.querySelectorAll('section.people.people-senior ul li h3');
	var senior_text = document.querySelectorAll('section.people.people-senior ul li p');
	if (senior_title.length > 0) {
		sameHeight(senior_title);
	}
	if (senior_text.length > 0) {
		sameHeight(senior_text);
	}

	// MANAGEMENT TEAM TEXT HEIGHT
	var management_title = document.querySelectorAll('section.people.people-management ul li h3');
	var management_text = document.querySelectorAll('section.people.people-management ul li p');
	if (management_title.length > 0) {
		sameHeight(management_title);
	}
	if (management_text.length > 0) {
		sameHeight(management_text);
	}


	var assetAllocationKeys = document.querySelectorAll('section.asset-allocation .aa-keys .container .single-key .single-key-content');
	if (assetAllocationKeys.length > 0) {
		sameHeight(assetAllocationKeys);
	}

	var downScroll = document.getElementById('downScroll');
	if (downScroll != null) {
		downScroll.onclick = function () {
			var hero_header = document.getElementsByClassName('hero-header').item(0);
			if ((hero_header == null) || (hero_header == undefined)) {
				hero_header = document.querySelectorAll('section.carousel-header .carousel .carousel-inner .item.active').item(0);
			}
			hero_header_h = hero_header.offsetHeight;
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

			var scrollingElement = document.documentElement;
			var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
			console.log(isSafari);
			if (isSafari == true) {
				var scrollingElement = document.body;
			}

			scrollingTo(scrollingElement, hero_header_h, 600);
		}
	}

	// SCROLL ANIMATION FUNC
	function scrollingTo(element, to, duration) {
		var start = element.scrollTop,
			change = to - start,
			currentTime = 0,
			increment = 20;
		var animateScroll = function () {
			currentTime += increment;
			var val = parseInt(Math.easeInOutQuad(currentTime, start, change, duration));
			element.scrollTop = val;
			if (currentTime < duration) {
				setTimeout(animateScroll, increment);
			}
		};
		animateScroll();
	}

	Math.easeInOutQuad = function (t, b, c, d) {
		t /= d / 2;
		if (t < 1) return c / 2 * t * t + b;
		t--;
		return -c / 2 * (t * (t - 2) - 1) + b;
	};


	// SHOW DISCLAIMER
	var theModal = document.querySelector('body.page-template-template-endowment-funt #modal-disclaimer.modal'); //added body selector for only page
	if ((localStorage.getItem("disclaimerStateDateTime") == null) && (theModal != null)) {
		setDisclaimer();
	} else if ((localStorage.getItem("disclaimerStateDateTime") != null) && (theModal != null)) {
		var getBrowserObj = JSON.parse(localStorage.getItem("disclaimerStateDateTime"));
		if (getBrowserObj != null) {
			var getBrowserTime = getBrowserObj.timestamp;
			now = new Date().getTime().toString();
			if ((now - getBrowserTime) > 604800000) {
				setDisclaimer();
			} else {
				console.log(604800000 - (now - getBrowserTime) + " to expiery of the disclaimer");
			}
		}
	}

	function setDisclaimer() {
		console.log('Its not shown');
		theModal.style.display = 'block';

		var btnAccept = document.getElementById('disclaimer-accept');
		btnAccept.onclick = function (ev) {
			ev.preventDefault();
			theModal.style.display = 'none';

			var object = { value: "Date Entered", timestamp: new Date().getTime() }
			localStorage.setItem('disclaimerStateDateTime', JSON.stringify(object));
		}
	}



	// REMOVE GOOGLE MAP FILTER
	var gMap = document.getElementById('map');
	if (gMap != null) {
		document.querySelector('.hero-header .filter-overlay').onclick = function () {
			this.style.display = 'none';
		}
	}

	// CHECK FOR HASHES
	checkForHash();
	var menuLinks = document.querySelectorAll('header #site-menu .menu-container .site-nav ul li ul li a');
	for (var ml = 0; ml < menuLinks.length; ml++) {
		menuLinks.item(ml).addEventListener('click', checkForHash);
	}
	window.addEventListener('hashchange', checkForHash);
	// INFOGRAPHIC COLUMN SIZES
	window.addEventListener('resize', infographicColumns());

} // end websiteLoad


// CHECK FOR HASHES
function checkForHash() {
	var hash = window.location.hash;
	if (hash.length > 0) {
		console.log(hash);
		var theHash = hash.replace(['!'], '');
		var el = document.querySelector(theHash);
		var elPosition = offset(el);
		window.scrollTo(window.scrollX, elPosition.top - 80);
	} else {
		console.log('The page has no hash')
	}
}
function offset(el) {
	if (el != null) {
		var rect = el.getBoundingClientRect(),
			scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
	}
}

// INFOGRAPHIC COLUMN SIZES
function infographicColumns() {
	var infoColumns = document.getElementsByClassName('infographic-column');
	if ((infoColumns != undefined) && (infoColumns.length > 0)) {
		if (window.innerWidth > 1200) {
			sameHeight(infoColumns);
			infoColumns[infoColumns.length - 1].style.minHeight = 'auto';
		} else {
			for (var i = 0; i < infoColumns.length; i++) {
				console.log(infoColumns[i]);
				infoColumns[i].style.minHeight = 'auto';
			}
		}
	}
}



// FRONT PAGE STATISTICS
function animateValue(el, start, end, duration) {
	var range = end - start;
	var current = start;
	var increment = end > start ? 1 : -1;
	var stepTime = Math.abs(Math.floor(duration / range));
	var obj = el;
	var timer = setInterval(function () {
		current += increment;
		obj.innerHTML = current;
		if (current == end) {
			clearInterval(timer);
		}
	}, stepTime);
}

// SAME HEIGHT FUNCTION
function sameHeight(el) {
	if ((el.length > 0) && (el != undefined)) {
		var mh = [];
		for (var i = 0; i < el.length; i++) {
			mh.push(el.item(i).offsetHeight);
		}
		gmh = Math.max.apply(null, mh);
		if (gmh != undefined) {
			for (var ii = 0; ii < el.length; ii++) {
				el.item(ii).style.minHeight = gmh + 'px';
			}
		}
	}
}

if (window.addEventListener) {
	window.addEventListener('scroll', websiteScroll);
} else if (window.attachEvent) {
	window.attachEvent('scroll', websiteScroll);
} else {
	window.onscroll = websiteScroll;
}
function websiteScroll() {
	// CHECK HEADER SCROLLTOP
	windowHeader();
	// ADD ANIMATION
	elementsToAnimate();
}


// ADD ANIMATION
function elementsToAnimate() {
	// addCustomAnimation('single-stat', 'class', 'visible', 250);
	addCustomAnimation('performance-stats', 'class', 'visible', 250);
}

function addCustomAnimation(el, elAttr, animation, gap) {
	var element = el;
	var elementAttr = elAttr;
	var elementAnimation = animation;
	var elementGap = gap;

	//gap before it activates
	if (elementGap == undefined) {
		var elementGap = 30;
	}
	if ((element != undefined) && (elementAttr != undefined) && (elementAnimation != undefined) && (elementGap != undefined)) {
		// gets windows bottom
		var winTop = ((window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop) + window.outerHeight) - elementGap;

		if (elementAttr == 'id') { //IF IS ID ELEMENT
			var el = document.getElementById(element);
			if (el != null) {
				var el_position_top = getElementTop(el);
				if (winTop > el_position_top) {
					el.classList.add(elementAnimation);
				}
			} else {
				return; // class doesnt exist
			}

		} else if (elementAttr == 'class') { //IF IS CLASS ELEMENT
			var elClass = document.getElementsByClassName(element);
			if (elClass.length > 0) {
				var elClass_object = {}

				// gets elements into a object
				for (var i = 0; i < elClass.length; i++) {
					elClass_object[i] = {
						element: elClass[i],
						heightFromTop: getElementTop(elClass[i]),
					}
				}
				// checks winTop according to el top

				for (var obj in elClass_object) {
					if (!elClass_object.hasOwnProperty(obj)) continue;
					var subObj = elClass_object[obj];
					if (subObj.heightFromTop < winTop) {
						subObj.element.classList.add(elementAnimation);
					}
				}
			} else {
				return; // class doesnt exist
			}


		} else if (elementAttr == 'tagInClass') { // IF IS TAGS INSIDE PARENT WITH CLASS

			var elTag = el.split(' ');
			var elTagClass = elTag[0];
			var elTagTags = elTag[1];

			var elTagParentChildren;
			var elTags_object = {};

			var elParent = document.getElementsByClassName(elTagClass);
			if (elParent.length > 0) {
				for (var i = 0; i < elParent.length; i++) {
					elTagParentChildren = elParent[i].children;
				}
				for (var i2 = 0; i2 < elTagParentChildren.length; i2++) {
					if (elTagParentChildren[i2].tagName == elTagTags.toUpperCase()) {
						elTags_object[i2] = {
							element: elTagParentChildren[i2],
							heightFromTop: getElementTop(elTagParentChildren[i2]),
						}

					} else {
						continue;
					}
				}
				for (var obj in elTags_object) {
					if (!elTags_object.hasOwnProperty(obj)) continue;
					var subObj = elTags_object[obj];
					if (subObj.heightFromTop < winTop) {
						subObj.element.classList.add(elementAnimation);
					}
				}
			} else {
				return; //if parent doesnt exist	
			}
		} else { // ELSE
			return;
		}
		// checks element top position
		function getElementTop(elTop) {
			var box = elTop.getBoundingClientRect();
			var body = document.body;
			var docEl = document.documentElement;

			var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
			var clientTop = docEl.clientTop || body.clientTop || 0;
			var top = box.top + scrollTop - clientTop;

			return top;
		}
	} else {
		console.log('addCustomAnimation is not defined');
	}

} // end addCustomAnimation

// HEADER SCROLLTOP
function windowHeader() {

	var doc = document.documentElement;
	var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
	var fixHeader = document.getElementsByTagName('header')[0];
	var fixHeaderClass = fixHeader.classList;

	if (top > 150) {
		if ((fixHeaderClass != null) && (fixHeaderClass != undefined)) {
			if (fixHeader != undefined) {
				if (fixHeader.classList.contains('fixOnScroll') == false) {
					fixHeader.classList.add('fixOnScroll');
				}
			}
		} else {
			if (fixHeader != undefined) {
				/* IE9 Fallback */
				if (((" " + fixHeader.className + " ").indexOf(" fixOnScroll ") > -1) == false) {
					fixHeader.className += 'fixOnScroll';
				}
			}
		}
	} else {
		if ((fixHeaderClass != null) && (fixHeaderClass != undefined)) {
			if (fixHeader != undefined) {
				if (fixHeader.classList.contains('fixOnScroll')) {
					fixHeader.classList.remove('fixOnScroll');
				}
			}
		} else {
			if (fixHeader != undefined) {
				if (((" " + fixHeader.className + " ").indexOf(" fixOnScroll ") > -1) == true) {
					/* IE9 Fallback */
					fixHeader.className = fixHeader.className.replace(new RegExp('(?:^|\\s)' + 'fixOnScroll' + '(?:\\s|$)'), ' ');
				}
			}
		}
	}
}




// GOOGLE MAP INNIT
window.marker = null;
function initialize() {
	var map;
	var nottingham = new google.maps.LatLng(51.752688, -1.267216);
	var style = [
		{
			"featureType": "road",
			"elementType":
				"labels.icon",
			"stylers": [
				{ "saturation": 1 },
				{ "gamma": 1 },
				{ "visibility": "on" },
				{ "hue": "#e6ff00" }
			]
		},
		{
			"elementType": "geometry", "stylers": [
				{ "saturation": -100 }
			]
		}
	];
	var mapOptions = {
		// SET THE CENTER
		center: nottingham,
		// SET THE MAP STYLE & ZOOM LEVEL
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		zoom: 15,
		// SET THE BACKGROUND COLOUR
		backgroundColor: "#eeeeee",
		// REMOVE ALL THE CONTROLS EXCEPT ZOOM
		panControl: false,
		scrollwheel: false,
		zoomControl: false,
		mapTypeControl: false,
		scaleControl: false,
		streetViewControl: false,
		overviewMapControl: false,
		zoomControlOptions: {
			style: google.maps.ZoomControlStyle.SMALL
		}
	}
	map = new google.maps.Map(document.getElementById('map'), mapOptions);

	// SET THE MAP TYPE
	// var mapType = new google.maps.StyledMapType(style, {name:"Grayscale"});    
	// map.mapTypes.set('grey', mapType);
	// map.setMapTypeId('grey');

	//CREATE A CUSTOM PIN ICON
	var marker_image = '../wp-content/themes/OUEM/assets/images/map-logo.png';
	var pinIcon = new google.maps.MarkerImage(marker_image, null, null, null, new google.maps.Size(36, 46));

	marker = new google.maps.Marker({
		position: nottingham,
		map: map,
		icon: pinIcon
	});
}
if (document.getElementById('map') != null) {
	google.maps.event.addDomListener(window, 'load', initialize);
}
